import { EUROPE_LOCALES, LOCALES } from '@/constants'

const urlParams = new URLSearchParams(window.location.search);
const lang = urlParams.has('lang') ? urlParams.get('lang') : '';

const isEuropeLang = EUROPE_LOCALES.includes(lang);

const euOrNaPath = isEuropeLang ? 'eu' : 'na';

const langPath = LOCALES.includes(lang) ? lang : 'en';

export default {
  fleet: {
    scalableSolution: [
      {
        id: 'fleet-cable-options',
        stationSide: 'front',
        pointPosition: [435, 345],
        videoUrl: `/assets/video/fleet/${euOrNaPath}/cableOptions.mp4`,
        previewUrl: '',
        title: 'cableOptionsTitle',
        text: 'cableOptionsDescr',
        lineHeight: 125,
        lineWidth: 350,
        titlePosition: 'right',
        infoPosition: 'center',
        infoTextStyle: { position: 'absolute', left: '70px', top: '23%', width: '230px' },
        infoVideoStyle: { width: '75%' }
      },
      {
        id: 'fleet-cable-management',
        stationSide: 'front',
        pointPosition: [110, 150],
        videoUrl: `/assets/video/fleet/${euOrNaPath}/cableManagement.mp4`,
        previewUrl: '',
        title: 'cableManagementTitle',
        text: 'cableManagementDescr',
        lineHeight: 60,
        lineWidth: 150,
        titlePosition: 'right',
        infoPosition: 'center',
        infoTextStyle: { position: 'absolute', left: '5%', top: '10%', width: '270px' },
        infoVideoStyle: { height: '100%' }
      },
      {
        id: 'fleet-scale-capacity',
        stationSide: 'front',
        pointPosition: [540, -40],
        videoUrl: '/assets/video/fleet/universal/scaleCapacity.mp4',
        previewUrl: '',
        title: 'scaleCapacityTitle',
        text: 'scaleCapacityDescr',
        lineHeight: 0,
        lineWidth: 0,
        titlePosition: 'right',
        infoPosition: 'left'
      },
      {
        id: 'fleet-high-power',
        stationSide: 'back',
        pointPosition: [60, 675],
        videoUrl: `/assets/video/fleet/${euOrNaPath}/highPower.mp4`,
        previewUrl: '',
        title: 'highPowerTitle',
        text: 'fleetHighPowerDescr',
        lineHeight: 100,
        lineWidth: 115,
        titlePosition: 'left',
        infoPosition: 'left'
      },
      {
        id: 'fleet-modularity',
        stationSide: 'back',
        pointPosition: [260, 675],
        videoUrl: '/assets/video/fleet/universal/modularity.mp4',
        previewUrl: '',
        title: 'modularityTitle',
        text: 'modularityDescr',
        lineHeight: 100,
        lineWidth: 115,
        titlePosition: 'left',
        infoPosition: 'left'
      },
      {
        id: 'fleet-scale-power',
        stationSide: 'back',
        pointPosition: [460, 675],
        videoUrl: `/assets/video/fleet/${euOrNaPath}/scalePower.mp4`,
        previewUrl: '',
        title: 'scalePowerTitle',
        text: 'fleetScalePowerDescr',
        lineHeight: 100,
        lineWidth: 115,
        titlePosition: 'left',
        infoPosition: 'left'
      }
    ],
    lifetimeCost: [
      {
        id: 'fleet-durability',
        stationSide: 'front',
        pointPosition: [435, 345],
        videoUrl: '/assets/video/fleet/universal/durability.mp4',
        previewUrl: '',
        title: 'durabilityTitle',
        text: 'durabilityDescr',
        lineHeight: 160,
        lineWidth: 350,
        titlePosition: 'right',
        infoPosition: 'left'
      },
      {
        id: 'fleet-design-link',
        stationSide: 'front',
        pointPosition: [700, 345],
        videoUrl: `/assets/video/fleet/${euOrNaPath}/design.mp4`,
        previewUrl: '',
        title: 'designTitle',
        text: 'designDescr',
        lineHeight: 120,
        lineWidth: 350,
        titlePosition: 'right',
        infoPosition: 'center',
        infoTextStyle: { position: 'absolute', right: '10%', top: '23%', width: '250px' },
        infoVideoStyle: { height: '100%' }
      },
      {
        id: 'fleet-charging',
        stationSide: 'front',
        pointPosition: [800, -65],
        videoUrl: `/assets/video/fleet/universal/charging/charging_${langPath}.mp4`,
        previewUrl: '',
        title: 'chargingTitle',
        text: 'chargingDescr',
        lineHeight: 0,
        lineWidth: 0,
        titlePosition: 'right',
        infoPosition: 'left'
      },
      {
        id: 'fleet-power-management',
        stationSide: 'back',
        pointPosition: [60, 675],
        videoUrl: `/assets/video/universal/power-management/power_management_${langPath}.mp4`,
        previewUrl: '',
        title: 'powerManagementTitle',
        text: 'powerManagementDescr',
        lineHeight: 100,
        lineWidth: 115,
        titlePosition: 'left',
        infoPosition: 'left'
      },
      {
        id: 'fleet-power-allocation',
        stationSide: 'back',
        pointPosition: [260, 675],
        videoUrl: '/assets/video/fleet/universal/allocation.mp4',
        previewUrl: '',
        title: 'allocationTitle',
        text: 'allocationDescr',
        lineHeight: 100,
        lineWidth: 115,
        titlePosition: 'left',
        infoPosition: 'left'
      },
      {
        id: 'fleet-design-power',
        stationSide: 'back',
        pointPosition: [460, 675],
        videoUrl: `/assets/video/universal/designPowerBlock.mp4`,
        previewUrl: '',
        title: 'designTitle',
        text: 'designDescr',
        lineHeight: 100,
        lineWidth: 115,
        titlePosition: 'left',
        infoPosition: 'center',
        infoTextStyle: { position: 'absolute', right: '15%', top: '23%', width: '230px' },
        infoVideoStyle: { height: '100%' }
      }
    ],
    businessIntegrated: [
      {
        id: 'fleet-dashboard',
        stationSide: 'front',
        pointPosition: [350, -50],
        videoUrl: '/assets/video/universal/dashboard.mp4',
        previewUrl: '',
        title: 'dashboardTitle',
        text: 'dashboardDescr',
        lineHeight: 0,
        lineWidth: 0,
        titlePosition: 'right',
        infoPosition: 'left'
      },
      {
        id: 'fleet-integrations',
        stationSide: 'front',
        pointPosition: [550, -50],
        videoUrl: `/assets/video/fleet/universal/integrations/integrations_${langPath}.mp4`,
        previewUrl: '',
        title: 'integrationsTitle',
        text: 'integrationsDescr',
        lineHeight: 0,
        lineWidth: 0,
        titlePosition: 'right',
        infoPosition: 'left'
      },
      {
        id: 'fleet-fleet-cloud',
        stationSide: 'front',
        pointPosition: [740, -50],
        videoUrl: '/assets/video/fleet/universal/fleetCloud.mp4',
        previewUrl: '',
        title: 'fleetCloudTitle',
        text: 'fleetCloudDescr',
        lineHeight: 0,
        lineWidth: 0,
        titlePosition: 'right',
        infoPosition: 'left'
      },
      {
        id: 'fleet-connectivity',
        stationSide: 'front',
        pointPosition: [160, -50],
        videoUrl: '/assets/video/universal/connectivity.mp4',
        previewUrl: '',
        title: 'connectivityTitle',
        text: 'connectivityDescr',
        lineHeight: 0,
        lineWidth: 0,
        titlePosition: 'right',
        infoPosition: 'left'
      }
    ],
    experiencedPartner: [
      {
        id: 'fleet-assure',
        stationSide: 'front',
        pointPosition: [110, -50],
        videoUrl: '',
        previewUrl: '/assets/preview/assure.png',
        title: 'assureTitle',
        text: 'assureDescr',
        lineHeight: 0,
        lineWidth: 0,
        titlePosition: 'right',
        infoPosition: 'left'
      },
      {
        id: 'fleet-support',
        stationSide: 'front',
        pointPosition: [350, -50],
        videoUrl: '',
        previewUrl: '/assets/preview/support.png',
        title: 'supportTitle',
        text: 'supportDescr',
        lineHeight: 0,
        lineWidth: 0,
        titlePosition: 'right',
        infoPosition: 'left'
      },
      {
        id: 'fleet-design-services',
        stationSide: 'front',
        pointPosition: [600, -50],
        videoUrl: '',
        previewUrl: '/assets/preview/designServices.png',
        title: 'designServicesTitle',
        infoTitle: 'designServicesInfoTitle',
        text: 'designServicesDescr',
        lineHeight: 0,
        lineWidth: 0,
        titlePosition: 'right',
        infoPosition: 'left'
      },
      {
        id: 'fleet-charge-everywhere',
        stationSide: 'front',
        pointPosition: [850, -50],
        videoUrl: '',
        previewUrl: `/assets/preview/${euOrNaPath}/driverNetwork.jpg`,
        title: 'chargeEverywhereTitle',
        infoTitle: 'chargeEverywhereInfoTitle',
        text: 'chargeEverywhereDescr',
        lineHeight: 0,
        lineWidth: 0,
        titlePosition: 'right',
        infoPosition: 'left'
      }
    ]
  },
  commercial: {
    scalableSolution: [
      {
        id: 'commercial-cable-management',
        stationSide: 'front',
        pointPosition: [110, 150],
        videoUrl: `/assets/video/commercial/${euOrNaPath}/cableManagement.mp4`,
        previewUrl: '',
        title: 'cableManagementTitle',
        text: 'cableManagementDescr',
        lineHeight: 60,
        lineWidth: 150,
        titlePosition: 'right',
        infoPosition: 'center',
        infoTextStyle: { position: 'absolute', left: '5%', top: '10%', width: '270px' },
        infoVideoStyle: { height: '100%' }
      },
      {
        id: 'commercial-scale-capacity',
        stationSide: 'front',
        pointPosition: [540, -40],
        videoUrl: '/assets/video/fleet/universal/scaleCapacity.mp4',
        previewUrl: '',
        title: 'scaleCapacityTitle',
        text: 'scaleCapacityDescr',
        lineHeight: 0,
        lineWidth: 0,
        titlePosition: 'right',
        infoPosition: 'left'
      },
      {
        id: 'commercial-high-power',
        stationSide: 'back',
        pointPosition: [60, 675],
        videoUrl: `/assets/video/commercial/${euOrNaPath}/highPower.mp4`,
        previewUrl: '',
        title: 'highPowerTitle',
        text: 'commercialHighPowerDescr',
        lineHeight: 100,
        lineWidth: 115,
        titlePosition: 'left',
        infoPosition: 'left'
      },
      {
        id: 'commercial-modularity',
        stationSide: 'back',
        pointPosition: [260, 675],
        videoUrl: '/assets/video/commercial/universal/modularity.mp4',
        previewUrl: '',
        title: 'modularityTitle',
        text: 'modularityDescr',
        lineHeight: 100,
        lineWidth: 115,
        titlePosition: 'left',
        infoPosition: 'left'
      },
      {
        id: 'commercial-scale-power',
        stationSide: 'back',
        pointPosition: [460, 675],
        videoUrl: `/assets/video/commercial/${euOrNaPath}/scalePower.mp4`,
        previewUrl: '',
        title: 'scalePowerTitle',
        text: 'commercialScalePowerDescr',
        lineHeight: 100,
        lineWidth: 115,
        titlePosition: 'left',
        infoPosition: 'left'
      }
    ],
    lifetimeCost: [
      {
        id: 'commercial-durability',
        stationSide: 'front',
        pointPosition: [435, 345],
        videoUrl: '/assets/video/commercial/universal/durability.mp4',
        previewUrl: '',
        title: 'durabilityTitle',
        text: 'durabilityDescr',
        lineHeight: 160,
        lineWidth: 350,
        titlePosition: 'right',
        infoPosition: 'left'
      },
      {
        id: 'commercial-design-link',
        stationSide: 'front',
        pointPosition: [700, 345],
        videoUrl: `/assets/video/commercial/${euOrNaPath}/design.mp4`,
        previewUrl: '',
        title: 'designTitle',
        text: 'designDescr',
        lineHeight: 120,
        lineWidth: 350,
        titlePosition: 'right',
        infoPosition: 'center',
        infoTextStyle: { position: 'absolute', right: '10%', top: '23%', width: '250px' },
        infoVideoStyle: { height: '100%' }
      },
      {
        id: 'commercial-power-management',
        stationSide: 'back',
        pointPosition: [60, 675],
        videoUrl: `/assets/video/universal/power-management/power_management_${langPath}.mp4`,
        previewUrl: '',
        title: 'powerManagementTitle',
        text: 'powerManagementDescr',
        lineHeight: 100,
        lineWidth: 115,
        titlePosition: 'left',
        infoPosition: 'left'
      },
      {
        id: 'commercial-power-allocation',
        stationSide: 'back',
        pointPosition: [260, 675],
        videoUrl: '/assets/video/commercial/universal/allocation.mp4',
        previewUrl: '',
        title: 'allocationTitle',
        text: 'allocationDescr',
        lineHeight: 100,
        lineWidth: 115,
        titlePosition: 'left',
        infoPosition: 'left'
      },
      {
        id: 'commercial-design-power',
        stationSide: 'back',
        pointPosition: [460, 675],
        videoUrl: `/assets/video/universal/designPowerBlock.mp4`,
        previewUrl: '',
        title: 'designTitle',
        text: 'designDescr',
        lineHeight: 100,
        lineWidth: 115,
        titlePosition: 'left',
        infoPosition: 'center',
        infoTextStyle: { position: 'absolute', right: '15%', top: '23%', width: '230px' },
        infoVideoStyle: { height: '100%' }
      }
    ],
    businessIntegrated: [
      {
        id: 'commercial-dashboard',
        stationSide: 'front',
        pointPosition: [50, -65],
        videoUrl: '/assets/video/universal/dashboard.mp4',
        previewUrl: '',
        title: 'dashboardTitle',
        text: 'dashboardDescr',
        lineHeight: 0,
        lineWidth: 0,
        titlePosition: 'right',
        infoPosition: 'left'
      },
      {
        id: 'commercial-business-cloud',
        stationSide: 'front',
        pointPosition: [240, -65],
        videoUrl: '/assets/video/commercial/universal/businessCloud.mp4',
        previewUrl: '',
        title: 'businessCloudTitle',
        text: 'businessCloudDescr',
        lineHeight: 0,
        lineWidth: 0,
        titlePosition: 'right',
        infoPosition: 'left'
      },
      {
        id: 'commercial-connectivity',
        stationSide: 'front',
        pointPosition: [430, -65],
        videoUrl: '/assets/video/universal/connectivity.mp4',
        previewUrl: '',
        title: 'connectivityTitle',
        text: 'connectivityDescr',
        lineHeight: 0,
        lineWidth: 0,
        titlePosition: 'right',
        infoPosition: 'left'
      },
      {
        id: 'commercial-branding',
        stationSide: 'front',
        pointPosition: [650, 245],
        videoUrl: '',
        previewUrl: `/assets/preview/${euOrNaPath}/branding.jpg`,
        title: 'brandingTitle',
        text: 'brandingDescr',
        lineHeight: 20,
        lineWidth: 250,
        titlePosition: 'right',
        infoPosition: 'center',
        infoTextStyle: { position: 'absolute', left: '10%', top: '23%', width: '230px' },
        infoVideoStyle: { height: '100%' }
      },
      {
        id: 'commercial-rewards',
        stationSide: 'front',
        pointPosition: [870, 310],
        videoUrl: '/assets/video/commercial/universal/rewards.mp4',
        previewUrl: '',
        title: 'rewardsTitle',
        text: 'rewardsDescr',
        lineHeight: 50,
        lineWidth: 310,
        titlePosition: 'right',
        infoPosition: 'left'
      }
    ],
    experiencedPartner: [
      {
        id: 'commercial-driver-network',
        stationSide: 'front',
        pointPosition: [60, -50],
        videoUrl: '',
        previewUrl: `/assets/preview/${euOrNaPath}/driverNetwork.jpg`,
        title: 'driverNetworkTitle',
        text: 'driverNetworkDescr',
        lineHeight: 0,
        lineWidth: 0,
        titlePosition: 'right',
        infoPosition: 'left'
      },
      {
        id: 'commercial-accessibility',
        stationSide: 'front',
        pointPosition:  [450, 120],
        videoUrl: `/assets/video/commercial/${euOrNaPath}/accessibility.mp4`,
        previewUrl: '',
        title: 'accessibilityTitle',
        text: 'accessibilityDescr',
        lineHeight: 130,
        lineWidth: 130,
        titlePosition: 'right',
        infoPosition: 'left'
      },
      {
        id: 'commercial-driver-experience',
        stationSide: 'front',
        pointPosition: [870, 345],
        videoUrl: '/assets/video/commercial/universal/driverExperience.mp4',
        previewUrl: '',
        title: 'driverExperienceTitle',
        text: 'driverExperienceDescr',
        lineHeight: 315,
        lineWidth: 350,
        titlePosition: 'right',
        infoPosition: 'left'
      },
      {
        id: 'commercial-assure',
        stationSide: 'front',
        pointPosition: [760, -50],
        videoUrl: '',
        previewUrl: '/assets/preview/assure.png',
        title: 'assureCommercialTitle',
        infoTitle: 'assureCommercialInfoTitle',
        text: 'assureCommercialDescr',
        lineHeight: 0,
        lineWidth: 0,
        titlePosition: 'right',
        infoPosition: 'left'
      },
      {
        id: 'commercial-support',
        stationSide: 'front',
        pointPosition: [980, -50],
        videoUrl: '',
        previewUrl: '/assets/preview/support.png',
        title: 'supportTitle',
        text: 'supportDescr',
        lineHeight: 0,
        lineWidth: 0,
        titlePosition: 'right',
        infoPosition: 'left'
      }
    ]
  }
}
