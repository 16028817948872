export function getXY(event) {
  const target = event.target;
  const rect = target.getBoundingClientRect();
  const x = event.changedTouches ? event.changedTouches[0].clientX : event.clientX;
  const y = event.changedTouches ? event.changedTouches[0].clientY : event.clientY;
  return {
    x: x - rect.left,
    y: y - rect.top
  }
}
