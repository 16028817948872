import Vue from 'vue'
import App from './App.vue'
import i18n from './lang'
import ToggleButton from 'vue-js-toggle-button'
import clickOutside from './directives/clickOutside'
 
Vue.use(ToggleButton)
Vue.directive('click-outside', clickOutside)

Vue.config.productionTip = false

new Vue({
  i18n,
  render: h => h(App)
}).$mount('#app')
