import { getXY } from './Utils';
import TWEEN from 'tween.js';

export const EVENTS = {
  RESIZE: 'resize',
  DRAW_FRAME: 'draw-frame',
  TWEEN_TO_FRAME: 'tween-to-frame'
};

export default class Station {
  constructor({
                node,
                imagesURLPattern,
                startFrame = 0,
                endFrame = 300,
                currentFrame = 0,
                keyFrames = [],
                frameWidth = 1000,
                frameHeight = 2000,
                rotateSpeed = 100,
                step = 10
              }) {
    this.node = node;
    this.nativeWidth = 0;
    this.nativeHeight = 0;
    this.rotateSpeed = rotateSpeed;
    this.pattern = imagesURLPattern;
    this.startFrame = startFrame;
    this.endFrame = endFrame;
    this.frameWidth = frameWidth;
    this.frameHeight = frameHeight;
    this.currentFrame = currentFrame;
    this.lastDrawFrame = -1;
    this.keyFrames = keyFrames;
    this.step = step;

    this.frames = [];
    this.isLoaded = false;
    this.startMove = false;
    this.moveDirection = 0;
    this.moveX = 0;
    this.ctx = node.getContext('2d');

    this.initCanvas();
    this.initEvents();

    this.tweenFrame = new TWEEN.Tween();
  }

  initCanvas() {
    this.calcCanvasSize();
  }

  initEvents() {
    this._onResize = this.onResize.bind(this);
    this._onMove = this.onMove.bind(this);
    this._onDown = this.onDown.bind(this);
    this._onUp = this.onUp.bind(this);

    this.node.addEventListener('mousemove', this._onMove);
    this.node.addEventListener('mousedown', this._onDown);
    window.addEventListener('mouseup', this._onUp);

    this.node.addEventListener('touchmove', this._onMove);
    this.node.addEventListener('touchstart', this._onDown);
    window.addEventListener('touchend', this._onUp);
    window.addEventListener('touchcancel', this._onUp);

    window.addEventListener('resize', this._onResize);
  }

  animate(time) {
    window.requestAnimationFrame(this.animate.bind(this));
    TWEEN.update(time);
  }

  stopFrameTweens() {
    this.tweenFrame.stop();
  }

  tweenToFrame(frame) {
    this.stopFrameTweens();
    const data = {index: this.currentFrame};
    const duration = (Math.abs(this.currentFrame - frame) * 1000) / this.rotateSpeed;
    this.tweenFrame = new TWEEN.Tween(data)
      .to({index: frame}, duration)
      .onUpdate(() => {
        this.setFrameIndex(data.index);
        this.drawCurrentFrame();
        this.node.dispatchEvent(new CustomEvent(
          EVENTS.TWEEN_TO_FRAME,
          {
            detail: {
              frame: data.index
            }
          }
        ));
      })
      .start();
  }

  tweenToPrevFrame() {
    const nextFrame = this.keyFrames.find(frame => this.currentFrame < frame) || this.endFrame + this.keyFrames[0];
    this.tweenToFrame(nextFrame);
  }

  tweenToNextFrame() {
    const nextFrame = [...this.keyFrames].reverse()
      .find(frame => this.currentFrame > frame) || [...this.keyFrames].pop() - this.endFrame;
    this.tweenToFrame(nextFrame);
  }

  get nodeWidth() {
    return this.node.getBoundingClientRect().width;
  }

  get size() {
    return {width: this.nativeWidth, height: this.nativeHeight};
  }

  getUrlForFrame(index) {
    return this.pattern.replace('{frame}', typeof index === 'string' ? index : index.toString()
      .padStart(4, '0'));
  }

  onResize() {
    this.calcCanvasSize();
  }

  calcCanvasSize() {
    const aspect = this.frameHeight / this.frameWidth;
    const rect = this.node.getBoundingClientRect();
    const dpr = window.devicePixelRatio || 1;
    this.nativeHeight = rect.height || this.node.clientHeight;
    this.nativeWidth = this.nativeHeight / aspect;
    this.node.width = this.nativeWidth * dpr;
    this.node.height = this.nativeHeight * dpr;

    this.node.dispatchEvent(new CustomEvent(
      EVENTS.RESIZE,
      {
        detail: {
          width: this.nativeWidth,
          height: this.nativeHeight
        }
      }
    ));
    if (this.isLoaded || this.frames.find(({index}) => index === this.currentFrame)) { // todo
      this.drawCurrentFrame(true);
    }
  }

  onMove(e) {
    e.preventDefault();
    if (!this.startMove) {
      return;
    }

    const positionX = getXY(e).x;
    const diffPersent = (this.moveX - positionX) / this.nodeWidth;
    const nextFrame = Math.round((this.endFrame - this.startFrame) * diffPersent);
    if (nextFrame) {
      this.stopFrameTweens();
      this.addFrameIndex(nextFrame);
      this.drawCurrentFrame();
      this.moveDirection = diffPersent;
      this.moveX = positionX;
    }
  }

  onDown(e) {
    e.preventDefault();
    this.startMove = true;
    this.moveX = getXY(e).x;
  }

  onUp(e) {
    if (this.startMove) {
      e.preventDefault();
      this.startMove = false;
      this.tweenToFrame(this.currentFrame <= this.endFrame / 2 ? this.startFrame : this.endFrame);
    }
  }

  _downloadImage(url) {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        resolve(img);
      };
      img.onerror = () => {
        resolve(null);
      };
      img.src = url;
    });
  }

  async loadFrames(frames, keys) {
    let current = 0;
    const steps = [...Array(Math.ceil(this.keyFrames.length / this.step))
      .keys()];
    /* eslint-disable */
    for (const _ of steps) {
      const stepKeys = keys.slice(current, current + this.step)
        .map((key) => {
          return this._downloadImage(this.getUrlForFrame(key))
            .then((img) => {
              frames[key] = {index: key, image: img};
              if (key === this.currentFrame) {
                this.drawCurrentFrame(true);
              }
            });
        });
      await Promise.all(stepKeys);
      current += this.step;
    }
    /* eslint-enable */
  }

  async load() {
    await this.loadFrames(this.frames, this.keyFrames);
    this.isLoaded = true;
  }

  setFrameIndex(index) {
    this.currentFrame = Math.round(index);
    if (this.currentFrame < this.startFrame) {
      this.currentFrame = this.endFrame - (this.startFrame - this.currentFrame);
    }
    if (this.currentFrame > this.endFrame) { // todo: из-за этого не попадает в нулевой фрейм
      this.currentFrame = this.startFrame + (this.currentFrame - this.endFrame);
    }
  }

  addFrameIndex(index) {
    this.setFrameIndex(this.currentFrame + index);
  }

  getFrameImage(index) {
    return this.frames[index] && this.frames[index].image;
  }

  drawFrame(image) {
    image && this.ctx.drawImage(image, 0, 0, this.node.width, this.node.height);
  }

  drawCurrentFrame(force = false) {
    if (!force && this.lastDrawFrame === this.currentFrame) {
      return;
    }
    this.drawFrame(this.getFrameImage(this.currentFrame));
    this.node.dispatchEvent(new CustomEvent(
      EVENTS.DRAW_FRAME,
      {detail: {frame: this.currentFrame}}
    ));
    this.lastDrawFrame = this.currentFrame;
  }

  dispose() {
    this.node.removeEventListener('mousemove', this._onMove);
    this.node.removeEventListener('mousedown', this._onDown);
    this.node.removeEventListener('mouseup', this._onUp);

    this.node.removeEventListener('touchmove', this._onMove);
    this.node.removeEventListener('touchstart', this._onDown);
    this.node.removeEventListener('touchend', this._onUp);
    this.node.removeEventListener('touchcancel', this._onUp);

    window.removeEventListener('resize', this._onResize);
  }
}
