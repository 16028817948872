<template>
  <div class="spot-info">
    <div class="spot-info__scroll-wrapper">
      <div class="spot-info__header">
        <div class="spot-info__mobile-title">
          {{ $t(title) }}
        </div>
        <div
          class="spot-info__close"
          @click="_onClickClose"
        >
          <img
            src="/assets/images/arrow-back.png"
            class="spot-info__close-image"
          >
        </div>
      </div>
      <div class="spot-info__text">
        <div class="spot-info__desktop-title">
          {{ $t(title) }}
        </div>
        <div class="spot-info__content">
          {{ $t(text) }}
        </div>
      </div>
      <div
        v-if="videoUrl"
        class="spot-info__video"
      >
        <div
          :class="playButtonClass"
          @click.stop="_onPlayButtonClick"
        />
        <video
          ref="player"
          :src="videoUrl"
          :poster="videoPreviewUrl"
          autoplay
          loop
          playsinline
          muted="muted"
          type="video/mp4"
          class="spot-info__player"
          @click.stop="_onVideoClick"
          @play="_onPlayVideo"
          @pause="_onPauseVideo"
          @loadstart="_onLoadStart"
          @loadeddata="_onLoadedData"
        />
      </div>
      <img
        v-if="!videoUrl && videoPreviewUrl"
        :src="videoPreviewUrl"
        class="spot-info__image"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'SpotInfo',
  props: {
    activeSpotId: {
      type: [String, null],
      default: null
    },
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    videoUrl: {
      type: String,
      default: ''
    },
    videoPreviewUrl: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isPlaying: false,
      isVideoLoading: false
    }
  },
  computed: {
    isPlayButtonVisible() {
      return this.isPlaying || this.isVideoLoading;
    },
    playButtonClass() {
      const playButtonClass = 'spot-info__play-button';
      return `${playButtonClass}${this.isPlayButtonVisible ? ` ${playButtonClass}--playing` : ''}`;
    }
  },
  watch: {
    activeSpotId(id) {
      if (!id) {
        this.$refs.player.pause();
      }
    }
  },
  methods: {
    _onPlayVideo() {
      this.isPlaying = true;
    },
    _onPauseVideo() {
      this.isPlaying = false;
    },
    _onPlayButtonClick() {
      this.$refs.player.play();
    },
    _onClickClose() {
      this.$emit('close');
    },
    _onVideoClick() {
      if (this.isPlaying) {
        this.$refs.player.pause();
      }
    },
    _onLoadStart() {
      if (this.videoUrl) {
        this.isVideoLoading = true;
      }
    },
    _onLoadedData() {
      this.isVideoLoading = false;
    }
  }
}
</script>

<style lang="scss">
@import "@vars";
@import "@mixins";

.spot-info {
  display: flex;
  background-color: #fff;
  border: 1px solid #7A9CAF;
  border-radius: 10px;
  padding: 19px;
  max-height: 100%;
  z-index: 1;

  @include media-down($tablet) {
    display: block;
    background: #fff;
    border: 2px solid #F5F5F5;
    border-radius: 5px;
    height: 100%;
    padding: 24px;
    width: 100%;
    z-index: 3;
  }

  @include media-down($phone) {
    border: none;
    overflow-y: auto;
  }

  &__scroll-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-height: 100%;
    min-height: 0;
    width: 100%;

    @include media-down($tablet) {
      align-items: stretch;
      justify-content: flex-start;
      height: 100%;
      max-height: none;
    }

    @include media-down($phone) {
      height: auto;
    }
  }

  &__header {
    display: none;

    @include media-down($tablet) {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
    }
  }

  &__mobile-title,
  &__desktop-title {
    font-size: 24px;
    line-height: 26px;
    color: #0076a8;
  }

  &__mobile-title {
    @include media-down($tablet) {
      font-size: 17px;
      line-height: 24px;
      margin-right: 20px;
    }
  }

  &__desktop-title {
    margin-bottom: 11px;

    @include media-down($tablet) {
      display: none;
    }
  }

  &__close {
    align-items: center;
    color: #567A92;
    cursor: pointer;
    display: flex;
    justify-content: center;
    font-size: 14px;
    visibility: hidden;
    width: 50px;

    @include media-down($tablet) {
      visibility: visible;
      width: 30px;
    }

    &-image {
      max-width: 100%;
      object-fit: contain;
    }
  }

  &__text {
    @include media-down($tablet) {
      background: transparent;
      border: none;
      position: static;
      width: auto;
    }
  }

  &__content {
    line-height: 22px;
    margin-bottom: 15px;

    @include media-down($tablet) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__video {
    align-items: center;
    background-color: #fff;
    display: flex;
    justify-content: center;
    max-width: 400px;
    max-height: 400px;
    min-height: 0;
    position: relative;

    @include media-down($tablet) {
      flex: 1;
      margin: 0 auto 10px;
      max-height: none;
      max-width: none;
      transform: none;
      width: 100%;
    }
  }

  &__play-button {
    background: url(../../assets/images/play.svg) no-repeat center center;
    cursor: pointer;
    height: 40px;
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    width: 40px;
    z-index: 1;

    &--playing {
      visibility: hidden;
    }
  }

  &__player {
    margin: auto;
    max-width: 100%;
    max-height: 100%;

    @include media-down($tablet) {
      width: 100%;
    }
  }

  &__image {
    align-self: center;
    max-height: 100%;
    max-width: 100%;
    min-height: 0;

    @include media-down($tablet) {
      margin-bottom: 25px;
    }
  }
}
</style>
