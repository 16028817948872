<template>
  <div class="tour__station-back-wrapper">
    <div class="tour__station-back">
      <template v-if="serviceIsLoaded">
        <img
          src="/assets/images/arrow-left.png"
          class="tour__rotate-icon-left"
        >
        <img
          src="/assets/images/arrow-right.png"
          class="tour__rotate-icon-right"
        >
      </template>
      <div
        ref="wrapper"
        class="tour__station-back-inner"
      >
        <canvas
          id="power-block-station-canvas"
          ref="powerBlockCanvasRef"
          class="tour__station-canvas"
        />
        <div
          v-if="!serviceIsLoaded"
          class="tour__loader-wrapper"
        >
          <img
            src="/assets/images/loader.png"
            class="tour__loader"
          >
        </div>
        <spot-item
          v-for="spot in spots"
          v-show="!isCanvasActive && firstFrameIsDrawn"
          :id="spot.id"
          :key="spot.id"
          v-click-outside="_onClickOutside"
          :title="spot.title"
          :position="spot.pointPosition"
          :title-position="spot.titlePosition"
          :line-height="spot.lineHeight"
          :line-width="spot.lineWidth"
          :is-mobile="isMobile"
          :scale="scale"
          :active-spot-id="activeSpotId"
          @select-spot="_onSelectSpot"
          @enter-spot="_onEnterSpot"
          @leave-spot="_onLeaveSpot"
        />
      </div>
      <div class="tour__station-title">
        Power Block
      </div>
    </div>
  </div>
</template>

<script>
import StationService from "@/services/Station";
import SpotItem from "@/components/spot/SpotItem";

export default {
  name: "PowerBlock",
  components: {SpotItem},
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      required: true
    },
    activeResolution: {
      type: String,
      required: true
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    activeSpotId: {
      type: String,
      default: null
    },
    spots: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      totalFrames: 150,
      service: null,
      frame: -1,
      availableFrames: [],
      scale: 1,
      isCanvasActive: false,
      firstFrameIsDrawn: false
    }
  },
  computed: {
    serviceIsLoaded() {
      return this.service && this.service.isLoaded
    }

  },
  watch: {
    isActive: {
      immediate: true,
      handler(value) {
        if (!value) {
          return;
        }

        if (!this.service) {
          this._initService()
        }

        if (this.service) {
          this.service.initCanvas()
        }

      }
    }
  },
  mounted() {
    this._prepareCanvas();
  },
  beforeUnmount() {
    this.service && this.service.dispose();
    this.$canvas().removeEventListener('mousedown', this._onDown);
    this.$canvas().removeEventListener('touchstart', this._onDown);
    window.removeEventListener('mouseup', this._onUp);
    window.removeEventListener('touchend', this._onUp);
  },
  methods: {

    _onSelectSpot(spotId) {
      this.$emit('select-spot', spotId);
    },

    _onEnterSpot(spotId) {
      this.$emit('enter-spot', spotId);
    },

    _onLeaveSpot() {
      this.$emit('leave-spot');
    },

    _onClickOutside() {
      this.$emit('click-outside');
    },

    _prepareCanvas() {
      const canvas = this.$canvas();

      canvas.addEventListener('resize', this._onResize);
      canvas.addEventListener('draw-frame', ({detail}) => {
        if (detail.frame === this.availableFrames[0]) {
          this.firstFrameIsDrawn = true;
        }
      });

      canvas.addEventListener('mousedown', this._onDown);
      canvas.addEventListener('touchstart', this._onDown);
      window.addEventListener('mouseup', this._onUp);
      window.addEventListener('touchend', this._onUp);
    },

    _getAvailableFrames() {
      return new Promise((resolve) => {
        const totalFramesNumbers = [...Array(this.totalFrames).keys()];
        resolve(totalFramesNumbers);
      });
    },

    _getImgUrlPattern() {
      const dirName = 'eu-na';
      const pattern = `${window.location.protocol}//${window.location.host}/assets/frames/power_block/${dirName}/{frame}.jpg?${Date.now()}`;
      return pattern;
    },

    $canvas() {
      return this.$refs.powerBlockCanvasRef;
    },

    _initService() {
      this.service && this.service.dispose();
      this._getAvailableFrames().then((availableFrames) => {
        this.availableFrames = availableFrames;
        this.frame = this.availableFrames[0];
        const imagesURLPattern = this._getImgUrlPattern();
        this.service = new StationService({
          node: this.$canvas(),
          imagesURLPattern,
          keyFrames: this.availableFrames,
          startFrame: this.frame,
          endFrame: [...this.availableFrames].pop(),
          frameWidth: 900,
          frameHeight: 1750,
          currentFrame: this.frame
        });
        this.service.load()
          .then(() => {
            this.service.drawCurrentFrame();
            this.service.animate();
          })
      });
    },

    _onDown() {
      this.isCanvasActive = true;
    },

    _onUp() {
      if (this.service && this.service.startMove) {
        this.$canvas().addEventListener('tween-to-frame', this._onAnimationEnd)
      }
    },

    _onAnimationEnd(event) {
      if (event.detail && (event.detail.frame === this.frame || event.detail.frame === [...this.availableFrames].pop())) {
        this.isCanvasActive = false;
        this.$canvas().removeEventListener('tween-to-frame', this._onAnimationEnd)
      }
    },

    _onResize() {
      if (this.isActive) {
        this.$nextTick(() => {
          if (this.$canvas() && this.frame >= -1) {
            const dpr = window.devicePixelRatio || 1;
            const canvasHeight = this.$canvas().height;
            const frameHeight = this.service.frameHeight;
            if (canvasHeight / dpr < frameHeight) {
              this.scale = canvasHeight / dpr / frameHeight;
            }
          }
        })
      }
    }
  }
}
</script>
<style lang="scss">
@import "@vars";
@import "@mixins";

.tour {
  &__station-back {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    position: relative;

    @include media-down($phone) {
      margin: 0;
    }

    &-wrapper {
      align-items: center;
      display: flex;
      grid-area: back;

      @include media-down($tablet) {
        height: 100%;
      }

      @include media-down($phone) {
        height: 50vh;
      }
    }

    &-inner {
      height: 85%;
      position: relative;
      margin-bottom: 3px;

      @include media-down($tablet) {
        margin-bottom: 1px;
      }

      @include media-down($phone) {
        height: 100%;
        margin: 0 0 3px 0;
      }
    }

    &-image {
      max-height: 100%;
      object-fit: contain;
    }
  }

  &__station-back &__rotate-icon-left,
  &__station-back &__rotate-icon-right {
    top: 60%;
    z-index: 1;
  }

  &__station-back &__rotate-icon-left {
    right: 91%;

    @include media-down($tablet) {
      right: auto;
      left: -6px;
    }
  }

  &__station-back &__rotate-icon-right {
    left: 91%;

    @include media-down($tablet) {
      left: auto;
      right: -7px;
    }
  }
}
</style>
