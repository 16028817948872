export const EUROPE_LOCALES = [
  'en-gb',
  'de-de',
  'fr-fr',
  'da-dk',
  'nl-nl',
  'it-it',
  'es-es',
  'no-no',
  'sv-se'
];

export const LOCALES = [
  'da-dk',
  'de-de',
  'en',
  'en-gb',
  'es-es',
  'fr-ca',
  'fr-fr',
  'it-it',
  'nl-nl',
  'no-no',
  'sv-se'
];
