<template>
  <div id="app">
    <Container />
  </div>
</template>

<script>
import Container from './components/layout/Container';

export default {
  name: 'App',
  components: {
    Container
  }
};
</script>

<style lang="scss">
@import "./assets/styles/index.scss";
</style>
